import React from 'react'
import Mini from '../components/miniSect';
import { StaticQuery, graphql, Link } from "gatsby";
import Layout from '../components/layout';
import QueryLike from '../components/stackedmini';
import MiniWithTitle from '../components/stackedminititle';

class RecursiveProducts extends React.Component{
    render(){
        let collections = this.props.edges.map((item)=> {
            if(item.node.frontmatter.subCollectionTitle != null){
                return <MiniWithTitle title={item.node.frontmatter.subCollectionTitle} subject={item.node.frontmatter.contains} />
            }
        })
        return(
            <div>
                {collections}
            </div>
        );
    }
}

class Products extends React.Component{
    render(){
        return(
            <Layout title="Products" hover="product" nomargin>
                <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark (
            sort: { fields: [ frontmatter___subCollectionTitle], order: DESC}
          ) {
            edges {
                node {
                    html
                    id
                    frontmatter {
                       path
            title
            subtitle
            belongsTo
            image
            descriptionTitle
            description
                    
                      collectionTitle
                      collectionList
                      
                      contains
                      isPartOf
                      subCollectionTitle
                    }
                }
            }
        }
      }
    `}
    render={data => (
      <header>
        <RecursiveProducts edges={data.allMarkdownRemark.edges} />
      </header>
    )}
  />
                
            </Layout>
        );
    }
}
export default Products