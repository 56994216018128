import React from 'react'
import Mini from '../components/miniSect';
import { StaticQuery, graphql, Link } from "gatsby";

import QueryLike from '../components/stackedmini';


class MiniWithTitle extends React.Component{
    render(){
        return(
            <div>
                 <p className="productsTitle">{this.props.title}</p>
                    <div className="productsSubHolder">
                    <QueryLike subject={this.props.subject} />
                    </div>
            </div>
           
        );
    }
}
export default MiniWithTitle