import React from 'react'
import Layout from '../components/layout'
import SideLayout from '../components/sideLayout'
import { graphql } from 'gatsby'
import Mini from '../components/miniSect';
import MiniTitled from '../components/stackedminititle';
import RecursiveProducts from '../components/recurisiveProducts';


export default function Template({data}){
    const {markdownRemark: post} = data;

    if(post.frontmatter.path == '/collections'){
        return <RecursiveProducts></RecursiveProducts>
    }

    if(post.frontmatter.subCollectionTitle != null){
        let trimmed = post.frontmatter.subCollectionTitle.replace(/ /g,'').toLowerCase();

        if(post.frontmatter.path == `/${trimmed}`){
            return(
                <Layout title={post.frontmatter.subCollectionTitle} nomargin hover="product">
                    <MiniTitled title={post.frontmatter.subCollectionTitle} subject={post.frontmatter.contains}></MiniTitled>
                </Layout>
            );
        }
    }

    

    if(post.frontmatter.path !=null)
    return(
        <Layout title={post.frontmatter.title} hover="product">
        
        <SideLayout link={post.frontmatter.link} head={post.frontmatter.title} side={post.frontmatter.subtitle} image={post.frontmatter.image} sideTitle={post.frontmatter.descriptionTitle} sideDescription={post.frontmatter.description} />
       
        </Layout>
    );
}

export const templateQuery =  graphql`
 query templateQueryByPath($path : String) {
     markdownRemark (frontmatter: { path: {eq: $path}}) {
         frontmatter {
            path
            title
            subtitle
            belongsTo
            image
            descriptionTitle
            description
            collectionTitle
            collectionList
            contains
            isPartOf
            subCollectionTitle
            link
         }
     }
 }
`